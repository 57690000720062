<template>
  <div align-v="center" class="d-flex position-relative sort-row-wrapper">
    <div class="count pr-3">
      <h6 class="m-0" v-if="$store.getters.listings">
        <b v-if="count" style="white-space: nowrap">{{ count }} {{$t("dashboard.label.vehicles")}}</b>
      </h6>
      <h6 v-else-if="loading"> {{ $t('search.label.loading') }}</h6>
      <h6 v-else>{{ $t("search.label.noResults") }}</h6>
    </div>

    <div
      class="px-0 py-0 has-order"
      v-if="
        this.getUserFilters.Model.length > 0 ||
        this.getUserFilters.Make.length > 0 ||
        this.getUserFilters.Year.length > 0
      "
    >
      <swiper :options="swiperOptions" ref="swiper" class="swiper">
        <swiper-slide v-for="make in getFiltersFlatten().Make" :key="make.TagValueId">
          <v-chip
            close
            @click:close="removeItemUserFilters({ type: 'Make', item: make })"
          >
            {{ make.Value }}
          </v-chip>
        </swiper-slide>
        <swiper-slide v-for="model in getFiltersFlatten().Model" :key="model.TagValueId">
          <v-chip
            close
            @click:close="removeItemUserFilters({ type: 'Model', item: model })"
          >
            {{ model.ParentTagValueLabel + " " + model.Value }}
          </v-chip>
        </swiper-slide>
        <swiper-slide v-if="getFiltersFlatten().Year.length">
          <v-chip
            close
            @click:close="removeItemUserFilters({ type: 'Year', item: [] })"
          >
            {{ getFiltersFlatten().Year[0] }} -
            {{ getFiltersFlatten().Year[1] }}
          </v-chip>
        </swiper-slide>
                    <!-- <swiper-slide v-if="!getFiltersFlatten().includeFees">
                      <v-chip
                        close
                        @click:close="removeItemUserFilters({ type: 'includeFees', item: false })"
                      >
                        Exclude Duties & Fees
                      </v-chip>
                    </swiper-slide> -->
        <!-- <template v-slot:button-prev>
          <div
            @click="$refs.swiper.swiperInstance.slidePrev()"
            class="swiper-button-prev"
          ></div>
        </template>
        <template v-slot:button-next>
          <div
            @click="$refs.swiper.swiperInstance.slideNext()"
            class="swiper-button-next"
          ></div>
        </template> -->
      </swiper>
    </div>
    <!-- <v-tabs show-arrows>
        <v-tab v-for="make in getFiltersFlatten().Make" :key="make.TagValueId">
          <v-chip
            close
            @click:close="removeItemUserFilters({ type: 'Make', item: make })"
          >
            {{ make.Value }}
          </v-chip></v-tab
        >
        <v-tab v-for="model in getFiltersFlatten().Model" :key="model.TagValueId">
          <v-chip
            close
            @click:close="removeItemUserFilters({ type: 'Model', item: model })"
          >
            {{ model.ParentTagValueLabel + " " + model.Value }}
          </v-chip></v-tab
        >
        <v-tab v-if="getFiltersFlatten().Year.length">
          <v-chip
            close
            @click:close="removeItemUserFilters({ type: 'Year', item: false })"
          >
            {{ getFiltersFlatten().Year[0] }} -
            {{ getFiltersFlatten().Year[1] }}
          </v-chip>
        </v-tab>
      </v-tabs> -->

    <!-- <div
      class="text-right ml-auto pl-3 clear-filter"
      v-if="
        this.getUserFilters.Model.length > 0 ||
        this.getUserFilters.Make.length > 0 ||
        this.getUserFilters.Year.length > 0
      "
    >
      <button @click="clearUserFilters">{{ $t('search.button.clearSearch') }}</button>
    </div> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _cloneDeep from "lodash/cloneDeep";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.min.css";
export default {
  props: ["count","loading"],
  computed: {
    ...mapGetters([
      "getUserFilters",
      "getUserFiltersByType",
      "getUserFilterList",
      "getUserFilterValToLabel",
      "getUserFilterLabelToVal",
      "isUserFiltered",
    ]),
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: "auto",
        observer: false,
        observeParents: true,
        loop: false,
        grab: "cursor",
      },
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    getFiltersFlatten() {
      const userFilter = _cloneDeep(this.getUserFilters);
      if (userFilter.Model && userFilter.Model.length > 0) {
        userFilter.Model = this.getUserFilterList(
          "Model",
          false,
          false,
          userFilter.Model
        );
        userFilter.Model.map((item) => {
          item.ParentTagValueLabel = this.getUserFilterList("Make", false, false, [
            item.ParentTagValueId,
          ])[0].Value;
          return item;
        });
      }
      if (userFilter.Make && userFilter.Make.length > 0) {
        userFilter.Make = this.getUserFilterList(
          "Make",
          false,
          false,
          userFilter.Make
        ).filter((make) =>
          userFilter.Model.every((item) => item.ParentTagValueId !== make.TagValueId)
        );
      }
      return userFilter;
    },
    ...mapActions(["removeItemUserFilters", "clearUserFilters"]),
  },
  mounted() {
    this.getFiltersFlatten();
  },
};
</script>

<style scoped lang="scss">
.sort-row-wrapper {
  display: flex;
  align-items: center;
  padding-right: 130px;
  overflow: hidden;
  @media (max-width: 991px) {
    padding-right: 0;
  }
}
.clear-filter {
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  background: #f8f9fa;
  @media (max-width: 991px) {
    top: 10px;
    height: 32px;
  }
  button {
    min-width: 70px;
    display: flex;
    align-items: center;
    border-radius: 40px;
    color: $light-blue;
    box-shadow: none;
    background: #eff1f3;
    padding: 4px 10px;
  }
}
.has-order {
  width: 100%;
  padding-right: 100px !important;
  @media (max-width: 991px) {
    order: 0;
    padding-right: 0 !important;
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.count {
  @media (max-width: 991px) {
    height: 46px;
    display: flex;
    align-items: center;
  }
}
</style>

<style lang="scss">
.sort-row-wrapper {
  .v-slide-group__content {
    justify-content: flex-start;
    column-gap: 10px;
  }
  .v-tab {
    padding: 0;
    min-width: auto;
    &:before {
      display: none !important;
    }
  }
  .v-tabs-slider-wrapper {
    display: none !important;
  }
  .v-tabs > .v-tabs-bar {
    background: none !important;
  }
  .v-slide-group__next,
  .v-slide-group__prev {
    min-width: 45px;
    @media (max-width: 991px) {
      min-width: 45px;
    }
  }
  .v-slide-group__next {
    @media (max-width: 991px) {
      margin-right: -15px;
    }
  }
  .v-slide-group__prev {
    @media (max-width: 991px) {
      margin-left: -15px;
    }
  }
  .v-ripple__container {
    display: none !important;
  }
  .v-icon {
    color: rgba(0, 0, 0, 0.54);
  }
  .swiper-slide {
    width: auto !important;
    margin-right: 10px;
  }
  .v-chip {
    cursor: pointer;
  }
}
</style>
